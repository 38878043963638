<template>
  <div class="login">
    <v-form ref="form" v-model="form" class="pa-5">
      <h1 class="text-center">Menu Dashboard</h1>
      <v-text-field
        :rules="mail_rules"
        v-model="phone"
        class="mb-10"
        label="رقم الهاتف"
      ></v-text-field>
      <!--/text filed -->
      <v-text-field
        :rules="passwordRules"
        v-model="password"
        :type="!visibility ? 'password' : 'text'"
        label="كلمة المرور"
      >
        <template slot="append">
          <v-btn @click="visibility = !visibility" small icon>
            <v-icon color="grey lighten-1">
              {{ visibility ? "visibility" : "visibility_off" }}
              <!--/ visiblility of password -->
            </v-icon>
          </v-btn>
        </template>
        <!--/ template -->
      </v-text-field>
      <!--/ password  -->
      <div class="d-flex">
        <v-btn
          @click="validate()"
          :disabled="!form || login_loading"
          width="100%"
          x-large
          class="mt-8"
          color="primary"
          :loading="login_loading"
        >
          دخول
        </v-btn>
      </div>
    </v-form>
    <!--/ form -->
    <v-snackbar width="auto" right v-model="snackbar.prop">
      {{ snackbar.err ? snackbar.errText : snackbar.succText }}
      <template v-slot:action="{ attrs }">
        <v-icon
          :color="snackbar.err ? snackbar.errColor : snackbar.succColor"
          v-bind="attrs"
          >{{ snackbar.err ? "highlight_off" : "done" }}</v-icon
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      login_loading: false,
      snackbar: {
        prop: false,
        err: true,
        errColor: "red lighteen-2",
        succColor: "primary",
        errText: "رقم الهاتف او  كلمة المرور غير صحيحة",
        succText: "تم التسجيل بنجاح",
      },
      form: false,
      visibility: false,
      phone:"",
      mail_rules: [(v) => !!v || "Mail number is required"],

      password:"",
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.login_loading = true; //  make submit button loading
        this.$store
          .dispatch("login", {
            phone_number: this.phone,
            password: this.password,
          })
          .then((res) => {
            this.snackbar.prop = true; // fade alert
            this.snackbar.err = false; // remove error from alert

            if (res.data.data.user_info.is_admin == 0) {
              this.$router.push({ name: "Products" });
            } else {
              this.$router.push({ name: "Stores" });
            }
          })
          .catch((err) => {
            this.login_loading = false; // remove submit loading
            this.snackbar.prop = true; // fade alert
            this.snackbar.err = true; // fade error of alert
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.v-form {
  width: 28rem;
}
h1 {
  margin-bottom: 100px;
}
</style>
